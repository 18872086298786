export const Sportifsitemsar = [
    {
        id: 1,
        title: "وليد كتيلة، النجم التونسي في رياضة المعاقين: بطل بارالمبي",
        date: "",
        content: `<b>مقدمة</b><br/><br/>
        وليد كتيلة، المعروف بلقب "النجم التونسي في رياضة المعاقين"، هو رمز للمثابرة والنجاح. وُلِد في 20 يوليو 1985 في تونس، وقد أثبت نفسه كواحد من أعظم الرياضيين البارالمبيين في العالم. مع العديد من الميداليات الذهبية في الألعاب البارالمبية وسجلات عالمية في جعبته، يواصل وليد كتيلة التألق على الساحة الدولية، ملهمًا الآلاف من الناس في تونس وخارجها.<br/><br/>
        <b>السيرة الذاتية</b><br/><br/>
        وليد كتيلة هو رياضي معاق متخصص في سباقات المسافات المتوسطة، وبشكل أساسي في فئة T34، التي تضم الرياضيين الذين يعانون من شلل دماغي يؤثر على أطرافهم السفلية. منذ بداياته، أظهر موهبة استثنائية وإرادة لا تتزعزع، ليصبح سريعًا شخصية بارزة في ألعاب القوى التونسية.<br/><br/>
        <b>المسيرة والإنجازات</b><br/>
        ترك وليد كتيلة بصمة في تاريخ الألعاب البارالمبية بفضل أدائه المذهل على المضمار:<br/><br/>
        <b>الألعاب البارالمبية في لندن 2012:</b> فاز بميداليتين ذهبيتين في 100 م و200 م T34، مما جعله قوة لا يُمكن تجاهلها في عالم رياضة المعاقين.<br/><br/>
        <b>الألعاب البارالمبية في ريو 2016:</b> حقق وليد ميدالية ذهبية في 100 م T34 وميدالية فضية في 800 م T34، مما عزز مكانته كبطل عالمي.<br/><br/>
        <b>الألعاب البارالمبية في طوكيو 2020:</b> مرة أخرى، سيطر على المنافسة بإضافة ميدالتين ذهبيتين جديدتين إلى سجله في 100 م و800 م T34.<br/><br/>
        بالإضافة إلى الألعاب البارالمبية، تألق وليد كتيلة أيضًا في بطولات العالم لألعاب القوى المعاقين، حيث فاز بالعديد من الميداليات الذهبية في مسابقات 100 م، 200 م، 400 م و800 م T34، مؤكداً مكانته كزعيم في فئته.<br/><br/>
        <b>الأرقام القياسية العالمية</b><br/>
        يمتلك وليد كتيلة عدة أرقام قياسية عالمية في فئة T34:<br/>
        100 م T34: 14.46 ثانية.<br/>
        200 م T34: 26.18 ثانية، تم تسجيله في نوتويل في 2 يونيو 2017.<br/>
        400 م T34: 49.8 ثانية، تم تسجيله في أربون في 27 مايو 2017.<br/>
        800 م T34: 1 دقيقة و39 ثانية و23، تم تسجيله خلال بطولة العالم 2023 في باريس.<br/>
        1500 م T34: 3 دقائق و9 ثواني و93، تم تسجيله في نوتويل في 3 يونيو 2017.<br/><br/>
        <b>الأداءات الأخيرة</b><br/>
        خلال بطولة العالم لألعاب القوى المعاقين في 2023 في باريس، أثبت وليد كتيلة مرة أخرى تفوقه بفوزه بالميدالية الذهبية في 800 م T34 بزمن مذهل قدره 1 دقيقة و39 ثانية و23. ساهم هذا الأداء في الميدالية الذهبية السابعة لتونس في هذه المسابقة، مما عزز مكانة البلاد في رياضة المعاقين الدولية.<br/><br/>
        <b>الأثر والإلهام</b><br/>
        بعيدًا عن نجاحاته الرياضية، يعد وليد كتيلة مصدر إلهام حقيقي للعديد من الأشخاص. تُظهر مسيرته أن العزيمة والعمل الجاد يمكن أن يتجاوزا جميع العقبات. إنه يمثل الأمل للشباب التونسيين، وخاصة أولئك الذين يعانون من إعاقة، من خلال إثبات أن الحدود يمكن دفعها.<br/>
        لقد لعب وليد كتيلة دورًا حيويًا في تعزيز رياضة المعاقين في تونس، مشجعًا العديد من الرياضيين الشباب على الانخراط في الرياضة ومتابعة أحلامهم، بغض النظر عن التحديات التي قد تواجههم.<br/><br/>
        <b>البدايات والدافع</b><br/>
        اكتشف وليد كتيلة رياضة ألعاب القوى بالصدفة عندما كان عمره 17 عامًا. جذبت طاقته المتدفقة انتباه ريدها سوداني، مدربه، الذي دعاه لتجربة سباقات الكراسي المتحركة. كانت تلك لحظة حاسمة بالنسبة لوليد، الذي سرعان ما طور شغفًا لهذه الرياضة.<br/>
        اليوم، في سن 38، لا ينوي الاعتزال قبل عام 2028، بعد الألعاب البارالمبية في لوس أنجلوس. لا تزال دوافعة سليمة، مدفوعة برغبة في تجاوز حدود إمكانياته ومواصلة التألق على الساحة العالمية.<br/><br/>
        <b>الروتين والعقلية</b><br/>
        الحياة اليومية لوليد كتيلة كرياضي على مستوى عالٍ صارمة. يتدرب بشكل مكثف، غالبًا لعدة ساعات في اليوم، ويشارك في معسكرات تدريبية في الخارج، مثل دبي وبولندا. بالنسبة له، يكمن مفتاح النجاح في الانضباط والتنظيم والتحسين المستمر.<br/>
        تعتمد فلسفته في الحياة على المثابرة وتحسين أدائه. يدير الضغط بالتركيز على أهدافه وتبني استراتيجيات جديدة لكل منافسة، مؤمنًا تمامًا بأن التكنولوجيا والمعدات الجيدة ضرورية للبقاء في القمة.<br/><br/>
        <b>الإنجازات والأثر في تونس</b><br/>
        كانت انتصارات وليد كتيلة لها تأثير كبير على الرياضة التونسية، خاصة في مجال رياضة المعاقين. لم تلهم نجاحاته فقط جيلًا جديدًا من الرياضيين، بل ساهمت أيضًا في زيادة الاعتراف وتطوير الرياضة للأشخاص ذوي الإعاقة في تونس.<br/>
        كثيرًا ما يشارك وليد كتيلة قصصًا عن بداياته الصعبة والعقبات التي واجهها قبل أن يحقق النجاح. انتظر عشر سنوات قبل أن يفوز بميدالياته الذهبية الأولى في الألعاب البارالمبية في لندن عام 2012.<br/><br/>
        <b>اقتباس ملهم:</b><br/>
        «لن أعتزل في القريب العاجل. هدفي هو الاستمرار في التفوق وتمثيل تونس بفخر على الساحة الدولية.»`
        ,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/wael.jpg`,
    },
    {
        id: 2,
        title: "مروى برهيمي: بطلة بارالمبية تونسية ملهمة",
        date: "",
        content: `
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">مروى برهيمي، وُلِدت في 19 سبتمبر 1988 في قفصة، هي واحدة من أكثر الرياضيين البارالمبيين إنجازًا في تونس. متخصصة في مسابقات الرمي في فئة F32، تغلبت على التحديات التي فرضتها إعاقتها الحركية لتصبح شخصية بارزة في الرياضة التونسية والدولية.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>البدايات والمسيرة</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">بدأت مروى مسيرتها الرياضية في عام 2008، حيث أظهرت بسرعة موهبة استثنائية. منذ عام 2009، كانت تشارك بالفعل في مسابقات دولية. كانت أولى مسابقاتها الكبيرة الدولية هي بطولة العالم لألعاب القوى IPC 2011 في كرايستشيرش، نيوزيلندا. في تلك المناسبة، حققت مروى ميدالية ذهبية في رمي الهراوة F31/32/51، مما يمثل بداية مسيرة رياضية مثيرة للإعجاب.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>النجاحات البارالمبية</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">كانت الألعاب البارالمبية في لندن عام 2012 مرحلة حاسمة في مسيرة مروى. حيث حققت ميدالية ذهبية في رمي الهراوة F32 بمجموع 23.80 م، مسجلة رقمًا قياسيًا جديدًا في المسابقة. أكدت مروى أنها قوة لا يُمكن تجاهلها في رياضة المعاقين.<br/>
				خلال الألعاب البارالمبية في ريو عام 2016، تكررت إنجازاتها بفوزها بميدالية فضية في رمي الجلة F32. ثم، في الألعاب البارالمبية طوكيو 2020، حققت ميدالية ذهبية جديدة في رمي الجلة، مضيفة ميدالية إلى رصيدها الفريد من الميداليات.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>الإنجازات الرياضية</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">بالإضافة إلى نجاحاتها في الألعاب البارالمبية، حققت مروى برهيمي العديد من الألقاب العالمية:<br/>
				•	ذهبية رمي الهراوة في بطولة العالم لألعاب القوى IPC في عام 2015.<br/>
				•	ذهبية رمي الجلة في بطولة العالم لألعاب القوى IPC عام 2019.<br/>
				•	أرقام قياسية عالمية في مسابقات رمي الهراوة والرمي الجلة في فئتها.<br/>
				•	بمجموعها، تمتلك مروى أكثر من 15 ميدالية دولية، مما يجعلها واحدة من أفضل الرياضيين البارالمبيين في تاريخ تونس.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>الإلهام</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">مروى برهيمي ليست مجرد رياضية بارالمبية، بل هي رمز للأمل والقوة. تؤمن بأن الإعاقة لا تعني عدم القدرة، بل تعني تحدي الذات. تلهم قصتها العديد من الشباب، سواء من ذوي الإعاقة أو غيرهم، للسعي وراء أحلامهم. عبر مشاركتها في المحافل الرياضية، تتحدى مروى الصور النمطية وتظهر أنه يمكن تحقيق النجاح، مهما كانت الظروف.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>خاتمة</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt; line-height:108%; font-size:12pt">
				<span style="font-family:'Times New Roman'">من خلال إنجازاتها الملهمة، تعد مروى برهيمي بطلة حقيقية للبارالمبية في تونس. تستمر قصتها في التأثير على العديد من الناس، وهي تجسد الروح الحقيقية للمنافسة.</span>
			</p>
			`,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/maroua.jpg`,
    },
    {
        id: 3,
        title: "ماهر رحماني: بطل بارالمبي تونسي في التجديف",
        date: "",
        content: `<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">ماهر رحماني، وُلِد في 15 أبريل 1992 في تونس، هو رياضي بارالمبي تونسي متخصص في التجديف في فئة PR1 M1x. على الرغم من التحديات المرتبطة بإعاقته، استطاع ماهر أن يصل إلى قمة رياضته بفضل عزيمته، وعمله الجاد، وإرادته الحديدية التي تدفعه دائمًا لتجاوز حدود قدراته.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				بداياته ومسيرته
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">اكتشف ماهر رياضة التجديف في سن الثامنة عشرة، وكانت تلك اللقاء نقطة تحول في حياته. سرعان ما أظهر موهبة استثنائية في هذه الرياضة الصعبة، سواء من الناحية البدنية أو النفسية. منذ بداياته، استطاع ماهر أن يُبهر بتقنيته وقدرته على الهيمنة على التخصص، حتى أمام منافسين ذوي خبرة. في عام 2018، حقق ميداليته الذهبية الأولى في بطولة أفريقيا، مما جعله متنافسًا جادًا على الساحة الدولية. كانت هذه الانتصارات بداية مسيرته الدولية، ومنذ ذلك الحين، لم يتوقف ماهر عن التقدم.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				&#xa0;
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>نجاحات بارالمبية</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">حقق ماهر رحماني تأهيله للألعاب البارالمبية في باريس 2024 بعد فوزه في سباق التجديف الفردي 2000 متر (PR1 M1x). أدت هذه الأداء الاستثنائي إلى حصوله على مكانة بين أفضل المتجدّفين البارالمبيين في العالم، وهو إنجاز ملحوظ يُظهر موهبته وإصراره.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">ومع ذلك، لم تكن المنافسة في باريس خالية من التحديات. خلال الألعاب البارالمبية في باريس 2024، تعرض ماهر لإصابة في الكوع أجبرته على الانسحاب مؤقتًا من المنافسة. كانت هذه الإصابة ضربة قوية، ليس فقط له ولكن أيضًا لداعميه وبلده. على الرغم من هذا التحدي، لم يفقد ماهر من نظره أهدافه. لا يزال مصممًا على العودة بقوة، والتعافي، ومواصلة تمثيل تونس بفخر. تُظهر قدرته على النهوض بعد النكسات ليس فقط قوته البدنية، ولكن أيضًا قوة ذهنية مدهشة.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>الحياة الشخصية</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">بعيدًا عن إنجازاته الرياضية، يعيش ماهر حياة شخصية غنية وملهمة. في عام 2021، تزوج من ليلى بن يوسف، رياضية سابقة في السباحة البارالمبية. يُعد اتحادهم مثالًا على الشراكة والدعم المتبادل، حيث يُظهر كيف يمكن لاثنين من الرياضيين تحفيز بعضهما البعض ودعم بعضهما البعض في السعي وراء أحلامهم الرياضية والشخصية. معًا، أنجبا ابنًا، ياسين، وُلِد في عام 2022، وهو مصدر إلهام دائم لماهر. إنه مرتبط جدًا بعائلته، التي دعمتهم طوال مسيرتهم.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">بالإضافة إلى التزاماته العائلية، يُعتبر ماهر نشطًا جدًا في المجتمع. يشارك في أنشطة التوعية والدعم للأشخاص ذوي الإعاقة في تونس، سعيًا لتعزيز الشمولية وتكافؤ الفرص للجميع. يُدعى بانتظام إلى المؤتمرات والفعاليات حيث يشارك قصته ومسيرته، ملهمًا بذلك الآخرين لتجاوز تحدياتهم الشخصية.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>أداء بارز في المنافسات الدولية</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">برع ماهر رحماني أيضًا في العديد من المنافسات الدولية في رياضة التجديف، بالإضافة إلى مشاركته في الألعاب البارالمبية في باريس 2024. إليك بعض مشاركاته الملحوظة:</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				&#xa0;
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>كأس العالم للتجديف 2024:</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				&#xa0;
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">كأس العالم III: احتل ماهر المركز السادس في سباق PR1 Men's Single Sculls بزمن 11:20·561. أظهر هذا الأداء قدرته على المنافسة مع أفضل المتجدّفين في العالم.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">كأس العالم II: شارك في عدة سباقات، حيث احتل المركز الخامس في Heat 2 بزمن 10:48·83، والرابع في مرحلة الإعادة بزمن 10:46·93، والثاني في النهائي B بزمن 10:50·561. تُظهر أداؤه المستمر خلال الموسم تحمله وإصراره على التفوق.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<strong>بطولة أفريقيا 2018:</strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				<span style="font-family:'Times New Roman'">حقق ماهر الميدالية الذهبية في فئة PR1 Men's Single Sculls، مُشيرًا إلى بداية مسيرته الدولية. كان هذا الانتصار نقطة تحول كبيرة في مسيرته، مؤكدًا مكانته كأحد أفضل المتجدّفين الأفارقة.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				بطولة العالم للتجديف 2023:
			</p>
			<p style="margin-top:0pt; margin-bottom:8pt">
				خلال بطولة العالم في بلغراد، أنهى ماهر أمام ليفا هلونغوا من جنوب أفريقيا، مما ضمن له تأهله للألعاب البارالمبية في باريس 2024. عزز هذا الأداء سمعته كمنافس عنيد وأكد مكانته كأحد المرشحين المفضلين للألعاب المقبلة.
			</p>`
        ,
        imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/maher.png`,
    },
    
        {
            id: 4,
            title: "فتحى زوينخى: نجم بارا-ترياثلون الأفريقي والعربي",
            date: "",
            content: `<p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">&#xa0;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">فتحى زوينخى، رياضى تونسى فى بارا-ترياثلون، هو شخصية بارزة في الرياضة في تونس وعلى القارة الأفريقية· يبلغ من العمر 38 عامًا، وقد صنع التاريخ كونه الممثل الأفريقي والعربي الوحيد في رياضة بارا-ترياثلون في الألعاب البارالمبية بباريس 2024· في هذه المسابقة المرموقة، أنهى فتحى في المركز السابع، مسجلًا وقتًا رائعًا قدره 1:05·491، مما يدل على موهبته الكبيرة وإخلاصه الثابت·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>الألقاب والإنجازات</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">على مدار مسيرته، حقق فتحى زوينخى سجلًا إنجازات مدهشًا:</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">بطل أفريقيا في بارا-ترياثلون: حصل على عدة ألقاب بطل أفريقيا، بأداء بارز في بطولة أفريقيا للترياثلون وبارا-ترياثلون في أبريل 2024 في مصر، حيث حصل على الميدالية الذهبية·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">ميداليات في البطولات العالمية: بين 2021 و2023، حصل فتحى على ميداليتين فضيتين وميداليتين برونزيتين في البطولات العالمية، مما عزز مكانته بين أفضل الرياضيين في مجاله على مستوى العالم·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">الألعاب البارالمبية: مثل تونس في الألعاب البارالمبية في 2008 و2016 و2020 و2024، وأظهر فتحى باستمرار صموده ومهارته، وبلغ ذروته بالمركز السابع في ألعاب باريس 2024·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>المسيرة والتصميم</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">ولد في 15 مارس 1986، بدأ فتحى زوينخى مسيرته الرياضية كسباح قبل أن يتوجه نحو بارا-ترياثلون· منذ أكثر من 15 عامًا، يتميز في رياضة عالية المستوى· تتميز مسيرته بعزيمة لا تتزعزع وقدرة على التغلب على العقبات· يتدرب يوميًا بجد، حيث يجمع بين السباحة وركوب الدراجات والجري على الكرسي المتحرك للحفاظ على مستوى أدائه الاستثنائي·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">يتدرب بانتظام في بوليغون فينسان في باريس، حيث يستعد مع مدربه أنيس حاج حسين للمسابقات الدولية· هذه الصرامة وروح القتال هما مفتاحا نجاحه، مما يلهم العديد من الرياضيين وعشاق الرياضة، ليس فقط في تونس، ولكن أيضًا في جميع أنحاء المنطقة العربية والأفريقية·</span>
                </p>
                `,
            imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/fathi.png`
        },
        {
            id: 5,
            title: "أحمد بن مصلاح: صورة لبطل بارالمبي",
            date: "",
            content: `
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">وُلِد أحمد بن مصلاح في 6 يناير 1995· منذ صغره، أظهر موهبة طبيعية في الرياضة، مما قاده إلى مسيرة رائعة كرياضي بارالمبي متخصص في رمي الكرة الحديدية·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>الألقاب والإنجازات</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">الألعاب البارالمبية طوكيو 2021: حصل أحمد على الميدالية الفضية في رمي الكرة الحديدية في فئة F37، مسجلاً أول نجاح كبير له على الساحة الدولية·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">الألعاب البارالمبية باريس 2024: كرر هذا الإنجاز بفوزه بميدالية فضية جديدة، هذه المرة برمية مثيرة قدرها 15.40 مترًا، مما أكد مكانته بين الأفضل في العالم في فئته·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">البطولات العالمية: تألق أحمد بن مصلاح أيضًا في البطولات العالمية، حيث جمع العديد من الميداليات التي عززت مكانته كرياضي بارز في مجاله·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>المسيرة</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">بدأ أحمد مسيرته الرياضية كرياضي بارالمبي متخصص في رمي الكرة الحديدية· منذ البداية، كانت مسيرته مميزة بعزيمة وصبر مثاليين· تغلب على العديد من التحديات، متدربًا بجد لتحسين أدائه باستمرار· بفضل التزامه الثابت، أصبح أحمد رمزًا للفخر لتونس، ممثلاً بلاده بشرف على الساحة الدولية·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>الإعاقة والتصنيف</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">يتنافس أحمد بن مصلاح في فئة F37، التي تضم الرياضيين الذين يعانون من شلل دماغي خفيف يؤثر بشكل رئيسي على جانب واحد من الجسم· يضمن هذا التصنيف تنافسًا عادلاً، مما يسمح لرياضيين ذوي مستويات قدرة بدنية مماثلة بالتنافس ضد بعضهم البعض· يسلط مشاركة أحمد في هذه الفئة الضوء على موهبته الطبيعية وقدرته على التغلب على التحديات البدنية بإصرار وقوة عقلية·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>أداء ملحوظ</strong>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <span style="font-family:'Times New Roman'">رمي الكرة الحديدية: كان أحد اللحظات البارزة في مسيرة أحمد هو رميه لمسافة 15.40 مترًا في الألعاب البارالمبية باريس 2024، الذي حصل من خلاله على الميدالية الفضية· أظهر هذا الأداء الاستثنائي ليس فقط موهبته، ولكن أيضًا قدرته على التفوق تحت الضغط·</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:8pt">
                    <strong>الاستمرارية في الأداء</strong>: <span style="font-family:'Times New Roman'">ما يميز أحمد بن مصلاح عن الرياضيين الآخرين هو استمراريته· لقد تمكن من البقاء بين الأفضل في مجاله، مظهرًا انتظامًا ملحوظًا في أدائه· هذه الاستمرارية هي نتاج تدريب صارم، وانضباط شخصي، وروح تنافسية لا تقهر</span>·
                </p>`
            ,
            imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBKvzjpo7fdgaq3kaEHxGqb5W8qPLXk7LgQ&usqp=CAU"
        },
        {
            id: 6,
            title: "ماهر بوعلاق، حامل الرقم القياسي في الرياضة البارالمبية التونسية",
            date: "4 نوفمبر 2024",
            content: `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">ماهر بوعلاق هو أحد أكثر الرياضيين البارالمبيين التونسيين تتويجًا، حيث حصل على سبع ميداليات بارالمبية، منها ست ذهبية، خلال دورتين متتاليتين فقط من الألعاب البارالمبية. بين الميداليات الذهبية البارالمبية، ألقاب البطولة، والاعتراف العالمي، يعد بوعلاق إحدى الشخصيات البارزة في رياضة ذوي الاحتياجات الخاصة في تونس وعلى المستوى الدولي.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">إنه الرياضي العربي الوحيد الذي حصد ثلاث ميداليات ذهبية أولمبية خلال دورتين مختلفتين.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;'>وُلِد في أغسطس 1969 مع إعاقة بصرية، حيث بدأت شغفه بالرياضة وحبه للتحدي يتطوران في سن مبكرة، على الرغم من التحديات المتعلقة بإعاقته. منذ بداياته، كان يتدرب جنبًا إلى جنب مع الرياضيين السليمين في الفريق الوطني، بسبب غياب الاعتراف الرسمي برياضة ذوي الاحتياجات الخاصة في تونس، خاصة للمكفوفين. "خلال تلك السنوات الأولى، شاركت بشكل أساسي في سباقات اختراق الضاحية وسباقات المضمار، مع تخصصات مثل 800 و1500 متر." قال بوعلاق. وسرعان ما برز بفضل أدائه المتميز، مما أثبت أنه قادر على المنافسة مع الأفضل، مهما كانت ظروفه.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">يشرح لنا أن عام 1998 يمثل نقطة تحول حاسمة في مسيرته المهنية. في هذا العام، قرر رئيس الاتحاد التونسي لرياضات المعاقين آنذاك، الراحل علي حرزالله، تعزيز وجود الرياضيين المعاقين والبحث عن مواهب واعدة. وهكذا تم اكتشاف بوعلاق بفضل موهبته الرياضية. بعد إجراء فحص طبي أكد إعاقته البصرية، تم اختياره للمشاركة في بطولات إقليمية بهدف الحصول على اعتراف دولي وتصنيف.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">شارك بوعلاق في عام 1998 في بطولة إفريقيا في الجزائر. بفوزه بثلاث ميداليات ذهبية خلال هذه الفعالية الرياضية الإقليمية، صنع لنفسه اسماً في عالم الرياضة الأفريقية وحقق دخوله الرسمي إلى النخبة كرياضي من ذوي الاحتياجات الخاصة مصنف في الفئة B3 للمكفوفين (فئة الإعاقات البصرية الخفيفة)، التي تقابل الفئة T13 في تصنيف اللجنة البارالمبية.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line-height:normal;background:white;'><span style="font-size:16px;font-family:Calibri;color:#222222;">هذا التصنيف يفتح مرحلة جديدة في مسيرته ويدفعه للتطلع إلى أهداف أكبر. من الآن فصاعدًا، يهدف إلى الألعاب البارالمبية لعام 2000 في سيدني. قرر مواصلة تقدمه للفوز بألقاب جديدة على المستوى الدولي فقط لتأكيد تصنيفه ضمن صفوف رياضيي ذوي الاحتياجات الخاصة. "في تونس، نظرًا لأن رياضة ذوي الاحتياجات الخاصة كانت لا تزال في بداياتها، كان علينا دائمًا إثبات أدائنا لرفع أي شك أو تساؤل،" قال بوعلاق. وأضاف أنه بعد مشاركته المتميزة في بطولة الجزائر وتصنيفه في الفئة B3، تمت دعوته مرة أخرى لتأكيد أدائه ولكن هذه المرة في أوروبا للحصول على اعتراف أكثر مصداقية. خلال مسابقة في هولندا في نفس العام، حصل على ميداليتين ذهبيتين في سباقي 800 و1500 متر، وفي نفس اليوم. وبعد بضعة أشهر، شارك في بطولة أخرى في مدريد بإسبانيا وحقق أداءً جديدًا بفوزه بعدة ميداليات. تم تأكيد أدائه إذن: تلك النجاحات لم تكن صدفة أو ضربة حظ، بل كانت نتيجة طبيعية لموهبة استثنائية.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;line
               `
            ,
            imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/maher1.jpg`
        },
        {
            id: 7,
            title: "وجدي بوخيلي: استعراض لمسيرة استثنائية لبطل بارالمبي",
            date: "31 أكتوبر 2024",
            content: `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">كان وجدي بوخيلي من بين الأسماء التي تألقت في دورة الألعاب البارالمبية بباريس 2024 بفوزه بالميدالية الذهبية في سباق الماراثون لفئة T12 في 8 سبتمبر الماضي، مما رفع رصيد الوفد التونسي إلى 11 ميدالية تتضمن<span style="color:black;background:white;"> 5 ميداليات ذهبية، 3 ميداليات فضية، و3 ميداليات برونزية</span>. </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">وُلد في 20 سبتمبر 1998 في جندوبة، ومرَّت مسيرته بالعديد من التحديات التي نجح خلالها في رفع راية الوطن في هذا المحفل الرياضي الكبير.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">في مسقط رأسه جندوبة، اكتشف الشاب البالغ من العمر 26 عامًا رياضة ألعاب القوى وطور شغفه بسباقات المسافات الطويلة. نشأ في بيئة متواضعة وبدأ مشواره الرياضي في النادي الرياضي للحرس الوطني عام 2014. ورغم الموارد المحدودة وإعاقته البصرية، لم تمنعه عزيمته من مواصلة المسيرة والمثابرة. لم يكتفِ بوخيلي بالأحلام، بل عمل بجد لتحسين أدائه وبناء عقلية فولاذية.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">بدأ احتكاكه بالرياضة الاحترافية في عام 2019 عندما انتقل إلى رادس وبدأ التدرب مع المنتخب الوطني التونسي. هناك ظهرت إمكانياته بشكل جديد بفضل تدريب المدربين الخبراء والبنية التحتية المناسبة التي لم تتوفر له في مسقط رأسه. مثلت تلك السنة نقطة الانطلاق نحو مسيرة مليئة بالنجاح والتحديات.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;line-height:107%;font-family:Aptos;">أول الانتصارات والدروس المستفادة من طوكيو</span></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">كانت سنة 2019 عامًا مميزًا في مسيرة صعود البطل البارالمبي. بعد مشاركته البارزة في البطولات الدولية في 2019، حلّ ثالثًا في نصف ماراثون فولفسبورغ بألمانيا وفاز ببطولة تونس للماراثون. في العام التالي، تميز مرة أخرى بفوزه بنصف ماراثون صفاقس وحصوله على المركز الثالث في ماراثون فولفسبورغ الدولي. كان صعوده سريعًا، لكن دورة الألعاب البارالمبية في طوكيو 2020 منحته اعترافًا دوليًا.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">في طوكيو، حلَّ بوخيلي في المركز السادس في سباقي 5000 متر والماراثون ضمن فئة T12. رغم أنه لم يحقق ميدالية، فإن تلك التجربة زادت من رغبته في تحقيق الانتصارات. شكلت طوكيو بداية إدراكه لقدراته الكاملة على المنافسة مع أفضل الرياضيين في البطولات القادمة.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:16px;line-height:107%;font-family:Aptos;">التتويج في الألعاب البارالمبية بباريس 2024</span></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">بعد أربع سنوات من التحضير المكثف، في 8 سبتمبر 2024، حقق بوخيلي ميداليته البارالمبية الأولى في باريس. قطع مسافة 42,195 كيلومترًا بزمن قدره ساعتان و22 دقيقة و5 ثوانٍ، ليحصد الميدالية الذهبية لفئة T12. أبهرت هذه النتيجة مئات الآلاف من التونسيين الذين أظهروا فخرهم عبر وسائل التواصل الاجتماعي واحتفلوا بفوزه.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">ببساطة، قال بوخيلي في منشور على صفحته الرسمية على فيسبوك بعد أيام من فوزه: "إنه الحلم الذي أصبح حقيقة".</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">كان وجدي بوخيلي من بين الرياضيين البارالمبيين الذين تم تكريمهم في قصر قرطاج من قبل رئيس الجمهورية في 11 سبتمبر تقديرًا لأدائهم الاستثنائي في الألعاب البارالمبية بباريس 2024. واعترافًا بميداليته الذهبية في سباق الماراثون T12، حصل بوخيلي على وسام الاستحقاق الوطني من فئة الرياضة.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:16px;line-height:107%;font-family:Aptos;">في حفل التكريم، تم الاحتفاء ببوخيلي كرمز للصمود والتفاني والفخر الذي يلهم الشباب التونسيين، وخاصة من هم في البيئات المتواضعة. تثبت مسيرته أن العمل الجاد والعزيمة قادران على التغلب على الصعاب. كل خطوة، وكل تضحية قدمها للوصول إلى هذا المستوى هي درس في الصمود والمثابرة. يبلغ من العمر 26 عامًا، ولا يزال لديه العديد من الصفحات الجميلة ليكتبها في تاريخ الرياضة التونسية.</span></p>
            `,
            imageUrl: `${process.env.PUBLIC_URL}/wikiphedia/wajdi.jpg`
        }
        
    
    
];
